@import "../../../common/vars";
@import "../../../common/breakpoints";
@import "../../../common/resets";

.container {
  position: relative;
}

.button {
  &.active {
    color: $athens-grey;
  }
}

.box {
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  box-shadow: $shadow-floating-box;
  background: $white;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 93%;
  height: 93%;
  overflow-y: scroll;
  z-index: 11;

  @include bp-min($md) {
    position: absolute;
    top: 30px;
    left: 0;
    width: 690px;
    height: auto;
    transform: none;
    overflow-y: auto;
  }

  ul {
    display: block;
    width: 100%;
    padding: 0 4px 0 24px;

    @include bp-min($md) {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-content: flex-start;
      height: 300px;
    }

    li {
      display: block;
      margin-top: 5px;
      margin-bottom: 5px;

      @include bp-min($md) {
        margin-right: 20px;
      }

      &:first-child {
        margin-top: 10px;
      }

      &:last-child {
        margin-bottom: 10px;
      }

      button {
        @extend %cleanButton;
        padding: 0;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 16px 16px 24px;
}

.input {
  flex: 1 1 auto;
}

.closeButton {
  flex: 0 0 auto;
  width: 50px;
  height: 50px;
  margin-left: 10px;
  padding: 0;

  @include bp-min($md) {
    margin-left: 24px;
    flex: inherit;
  }

  svg {
    width: 20px;
    height: 20px;
  }
}
