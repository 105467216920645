@import "../../../common/vars";

.item {
  position: relative;
  display: flex;
  border-bottom: 1px solid $zircon;
  padding: 16px 20px;

  &:last-child {
    border-bottom: none;
  }
}

.itemRemoveButton {
  position: absolute;
  right: 5px;
  top: 8px;
  width: 40px !important;
  height: 40px !important;

  svg {
    width: 13px;
    height: 13px;
  }
}

.itemPicture {
  width: 56px;
  max-height: 68px;
  overflow: hidden;
  flex: 0 0 auto;
  margin-right: 20px;

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.itemContent {
  flex: 1 1 auto;
  font-size: $small-font-size;
  line-height: 20px;
  padding-right: 25px;
}

.itemType {
  color: $submarine;
}

.itemName {
  color: $gunmetal;
}

.itemInfo {
  display: flex;
  margin-top: 4px;
  font-size: $regular-font-size;
}

.itemPrice {
  color: $gunmetal;
  margin-right: 12px;
}

.itemAmount {
  color: $submarine;
}
