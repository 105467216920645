@import "../../../common/common";
@import "../../../common/vars";
@import "../../../common/breakpoints";

.sticky {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 5;
  margin: 20px 40px 0 0;
  border-radius: 5px;
  background: rgba($white, 0.75);
}
