@import '../../../common/common';
@import '../../../common/vars';
@import '../../../common/breakpoints';

.headerContainer {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
}

.left {
  display: flex;
  align-items: center;
  flex: 1 0 50%;

  @include bp-min($md) {
    padding-bottom: 15px;
  }
}

.right {
  display: flex;
  align-items: center;

  @include bp-min($md) {
    padding-bottom: 15px;
  }
}

.logo {
  display: block;
  width: 76px;
  margin-right: 10px;
  flex-shrink: 0;

  img {
    width: 100%;
  }

  @include bp-min($xsm) {
    width: 94px;
    margin-top: 3px;
  }

  @include bp-min($md) {
    width: 132px;
    margin-right: 30px;
    margin-top: 0;
  }

  @include bp-min($lg) {
    width: auto;
    margin-right: 91px;
  }
}

.menuButton {
  width: 24px !important;
  height: 28px !important;
  align-items: flex-start !important;
  display: block;
  padding: 0;
  margin-right: 10px !important;
  background: none !important;
  border: none;
  transition: transform 300ms !important;
  outline: none;

  &:hover {
    background: none !important;
  }

  &.active {
    transform: rotate(720deg);
  }

  @include bp-min($sm) {
    margin-right: 28px;
  }

  @include bp-min($md) {
    display: none !important;
  }
}

.searchMode {
  .logo,
  .menuButton,
  .cart,
  .favoritesLink {
    display: none;

    @include bp-min($sm) {
      display: block;
    }
  }

  .menuButton {
    @include bp-min($md) {
      display: none;
    }
  }
}

.favoritesLink {
  @media (max-width: 400px) {
    button {
      width: 36px;
      height: 36px;
    }
  }
}

.searchButton,
.closeSearchButton {
  color: $charade;
  margin-right: 5px;
}

.searchButton,
.closeSearchButton {
  padding: 0;

  @include bp-min($md) {
    width: 44px;
    height: 44px;
  }

  @media (max-width: 400px) {
    width: 36px !important;
    height: 36px !important;
  }
}

.searchButton {
  svg {
    width: 27px;
    height: 27px;
  }
}

.closeSearchButton {
  @include bp-min($sm) {
    width: 44px !important;
  }

  svg {
    width: 23px;
    height: 23px;
  }
}

.headerLine {
  @include bp-min($md) {
    width: 100%;
    border-bottom: 1.5px solid $iron;
  }
}
