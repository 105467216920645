@import "../../../common/vars";
@import "../../../common/breakpoints";

.menu {
  display: block;
  position: relative;
  z-index: 3;
  top: -6px;
  border: 1px solid $zircon-light;
  border-radius: 0 0 6px 6px;
  padding: 6px 0 0;
  overflow: hidden;

  @include bp-min($md) {
    box-shadow: $shadow-floating-box;
    border-radius: 6px;
    background: $white;
    position: absolute;
    top: 27px;
    right: 0;
    width: 190px;
    padding: 7px 0 5px;
  }

  li, a {
    display: block;
  }

  a {
    padding: 7px 20px;

    &.special {
      color: $cinnabar;
    }

    &:hover {
      background: $iron;
    }
  }
}
