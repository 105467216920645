@import "../../../common/vars";
@import "../../../common/breakpoints";

.container {
  position: absolute;
  background: $white;
  border: 1px solid $zircon;
  border-radius: 6px;
  width: 93%;
  z-index: 2;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 4px 250px 130px rgba(63, 69, 71, 0.5);

  @include bp-min($md) {
    box-shadow: 0 4px 28px 0 rgba(63, 69, 71, 0.12);
    top: auto;
    right: 0;
    left: initial;
    width: 320px;
    margin-top: 10px;
    transform: none;
  }
}

.header {
  font-weight: 900;
  font-size: $large-font-size;
  color: $gunmetal;
  line-height: 28px;
  padding: 15px 20px;
  border-bottom: 1px solid $zircon;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.closeButton {
  float: right;
  width: 23px !important;
  height: 23px !important;
}

.list {
  max-height: 240px;
  overflow-y: auto;
}

.empty {
  padding: 15px 20px;
  font-size: $regular-font-size;
}

.checkout {
  border-top: 1px solid $zircon;
  padding: 20px;

  a {
    display: block;
    width: 100%;
    border-radius: 6px;
    background: $medium-sea-green;
    color: $white;
    font-size: $regular-font-size;
    outline: none;
    border: none;
    padding: 10px 0;
    text-align: center;
  }
}

.checkoutVTB {
  a {
    background: $vtb-blue;
  }
}
