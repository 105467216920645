@import "../../../common/vars";
@import "../../../common/breakpoints";
@import "../../../common/resets";

.subMenusPerspectiveContainer {
  @include bp-min($md) {
    position: absolute;
    perspective: 1000px;
    left: 0;
    top: 100%;
    width: 100%;
  }
}

.subMenus {
  display: none;
  background: $white;
  padding: 16px 0 16px 30px;
  position: relative;
  transform-origin: top;
  box-shadow: 0 2px 10px 0 rgba(63, 69, 71, .08);
  border: 1px solid #ebecec;
  box-sizing: border-box;

  @include bp-min($md) {
    z-index: 3;
    padding: 0px 40px;
    width: 100%;
    align-items: stretch;
    box-shadow: none;

    &.boxShadow {
      box-shadow: 0px 3.292px 8.23px 0px rgba(0, 0, 0, .1);
    }
  }

  &.focus {
    display: block;

    @include bp-min($md) {
      display: flex;
      position: absolute;
      left: 0px;
      top: 100%;
      width: 100%;
      justify-content: space-between;
      animation: curtain .4s;
    }
  }
}

@keyframes curtain {
  0% {
    transform: rotateX(-90deg);
  }
  100% {
    transform: rotateX(0deg);
  }  
}

@keyframes curtain-up {
  0% {
    transform: rotateX(-10deg) translateY(20px);
  }
  100% {
    transform: rotateX(0deg) translateY(0px);
  }  
}

.subMenu {
  @include bp-min($md) {
    border: none;
  }

  &:last-child .subMenuLabelContainer {
    padding-right: 0px;
  }
}

.subMenuLabelContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  flex-wrap: wrap;

  @include bp-min($md) {
    align-items: flex-start;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    flex-wrap: nowrap;
  }
}

.subMenuLabel {
  display: block;
  padding: 10px 0px;
  font-size: $regular-font-size;
  color: $gunmetal;
  cursor: pointer;
  transition: .3s;
  flex-basis: 50%;
  text-decoration: none !important;

  &:visited {
    color: $gunmetal;
  }

  &:hover {
    color: $iron-dark;
  }

  @include bp-min($md) {
    text-align: center;
    font-size: $small-font-size;
    flex-basis: 100%;
  }

  @include bp-min($lg) {
    font-size: $regular-font-size;
  }
}

.subMenuChevron {
  @extend %cleanButton;
  margin-left: 4px;
  flex: 0 0 80px;
  padding: 0 30px;

  @include bp-min($md) {
    display: none;
  }

  &.active {
    transform: rotate(-180deg);
  }

  img {
    width: 100%;
    height: auto;
  }
}

.subMenuListWrapper {
  display: none;
  transform-origin: top;
  width: 100%;

  &.active {
    display: block;
  }

  @include bp-min($md) {
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;

    &.active {
      display: flex;
      animation: curtain-up .3s;
    }
  }
}

.subMenuListBgWhite {
  display: none;

  @include bp-min($md) {
    display: block;
    position: absolute;
    z-index: -2;
    background: white;
    width: 100%;
    height: 100%;
  }
}

.subMenuListBgImage {
  display: none;

  @include bp-min($md) {
    display: block;
    position: absolute;
    z-index: -1;
    right: 40px;
    top: 20px;
    max-height: calc(100% - 40px);
  }
}

.subMenuList {
  @include bp-min($md) {
    display: flex;
    background: transparent;
    max-height: 300px;
    width: 100%;
    padding: 20px 40px;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: flex-start;
    box-shadow: 0px 3.292px 4.23px 0px rgba(0, 0, 0, .1);
  }

  li {
    display: block;

    a {
      display: block;
      font-size: $regular-font-size;
      color: $gunmetal;
      height: 100%;
      width: 300px;
      padding: 8px 0px;

      &:hover {
        color: $iron-dark;
      }

      &:focus {
        color: $gunmetal;
      }

      @include bp-min($md) {
        font-size: $small-font-size;
      }

      @include bp-min($lg) {
        font-size: $regular-font-size;
      }
    }
  }
}

.subMenuListOther {
  @include bp-min($md) {
    flex-direction: row;
    flex-wrap: nowrap;
  }
}

.FourthLevelMenuContainer {
  margin-right: 40px;

  a {
    width: auto !important;
  }
}

.FourthLevelMenuTitle {
  height: auto !important;
  width: auto !important;
  font-weight: bold;
}