@import "../../../common/vars";
@import "../../../common/resets";
@import "../../../common/breakpoints";

.menusContainer {
  display: block;
  z-index: 10 !important;
  position: fixed;
  left: -200%;
  top: 0;
  width: 100%;
  height: 100%;
  transition: .6s;


  .closeButton {
    position: fixed;
    top: 5px;
    right: -48px;
    color: inherit;
    z-index: 4;
    transition: .4s;
    color: black;
  }

  &.active {
    display: block;
    left: 0;

    .closeButton {
      right: 15px;
    }
  }

  @include bp-min($md) {
    display: flex;
    position: static;
    z-index: auto;
    width: auto;
  }
}

.menus {
  display: flex;
  flex-direction: column;
  left: 0;
  top: 0;
  background: $white;
  z-index: 3;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  padding: 40px 0 0;

  &.active {
    display: flex;
  }

  @include bp-min($md) {
    display: block;
    position: static;
    width: auto;
    height: auto;
    overflow-y: initial;
    box-shadow: none;
    padding: 0;
  }

  &.searchMode {
    @include bp-min($md) {
      display: none;
    }
  }

  a {
    outline: none;
    text-decoration: none;
  }

  nav {
    @include bp-min($md) {
      display: flex;
      height: 100%;
    }
  }

  nav > ul {
    display: block;
    width: 100%;

    @include bp-min($md) {
      display: flex;
      flex-direction: row;
    }

    li {
      @include bp-min($md) {

        &:last-child {
          margin-right: 0;
        }
      }

      @include bp-min($xmd) {
        margin-right: 39px;
      }
    }
  }
}

.menusItemRow {
  display: flex;
  height: 100%;

  a {
    position: relative;
    display: flex;
    align-items: center;
    color: $gunmetal;
    font-size: $regular-font-size;
    padding: 15px 0 15px 30px;
    flex: 1 1;
    transition: .3s;

    @include bp-min($md) {
      padding: 0 5px;
    }

    @include bp-min($xl) {
      font-size: $large-font-size;
    }

    &.active {
      color: $iron-dark;
    }

    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 100%;
      height: 14px;
      width: 100%; 
      transition: .3s;
      border-bottom: 2px solid black;
      opacity: 0;
    }

    &.active::after {
      @include bp-min($md) {
        opacity: 1;
      }
    }
  }
}

.chevron {
  @extend %cleanButton;
  margin-left: 4px;
  flex: 0 0 80px;
  padding: 0 30px;

  @include bp-min($md) {
    display: none;
  }

  &.active {
    transform: rotate(-180deg);
  }

  img {
    width: 100%;
    height: auto;
  }
}

