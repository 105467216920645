$formular: Formular, Arial, sans-serif;

// colors
$black: #000;
$night-rider: #333;
$white: #fff;

$whitesmoke: #f5f5f5;

// color names from https://www.color-blindness.com/color-name-hue/
$gunmetal: #2a3133;
$charade: #3f4547;
$nevada: #696f6f;
$submarine: #949899;
$iron-dark: #c5c8c8;
$iron: #c9cbcc;
$athens-grey: #dfe0e0;
$zircon: #ebecec;
$zircon-light: #f5f6f6;

$medium-sea-green: #3cb868;
$pelorous: #2f96b4;
$sunset-orange: #f44a36;
$cinnabar: #eb4734;

// shadows
$shadow-floating-box: 0 2px 10px 0 rgba(63, 69, 71, 0.08);

// font-sizes
$small-font-size: 13px;
$regular-font-size: 15px;
$large-font-size: 20px;
$xlarge-font-size: 24px;
$xxlarge-font-size: 32px;
$xxxlarge-font-size: 60px;

// breakpoints
$xs: 0px; // material-ui
$xsm: 375px;
$sm: 740px; // material-ui
$md: 940px; // material-ui
$xmd: 1140px;
$lg: 1240px; // material-ui
$xl: 1440px; // material-ui
$xxl: 1640px;
$xxxl: 2000px;

// vendor variables
$vtb-group: "VTB Group", Arial, sans-serif;
$vtb-group-condensed: "VTB Group Condensed", Arial, sans-serif;

$vtb-black: #17181a;
$vtb-blue: #002882;
$vtb-light-blue: #00aaff;
$vtb-lightest-blue: #e5f6ff;
$vtb-transparent-blue: #f7fcff;
$vtb-gray: #7d7e80;
$vtb-light-gray: #f5f6f8;
$vtb-red: #e62632;
