@import "../common/vars";
@import "../common/fonts";
@import "../common/breakpoints";

.container {
  font-family: $formular;
  top: 0;
  z-index: 4;
  position: fixed;
  width: 100%;

  @include bp-min($md) {
    position: static;
    width: 100%;
  }
}

.containerVTB {
  font-family: $vtb-group;
}
