@import "../../../common/vars";
@import "../../../common/breakpoints";
@import "../../../common/resets";

.pane {
  flex: 1;
  width: 100%;

  @include bp-min($md) {
    display: none;
  }
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;

  > ul, > ul > li {
    display: block;
  }
}

.menu {
  display: block;
  background: $charade;
  margin-top: auto;
  height: 100%;

  li, li > a, li > div > button {
    display: block;
  }

  li > a, li > div > button {
    @extend %cleanButton;
    font-size: $small-font-size;
    padding: 15px 30px;
    border-bottom: 1px solid $nevada;
    color: $submarine;
    width: 100%;

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      text-decoration: underline;
    }
  }
}
