@import '../../../common/common';
@import '../../../common/vars';
@import '../../../common/breakpoints';

.sticky {
  position: fixed;
  width: calc(100% - 40px);
  max-width: $xxxl - 40px;
  left: 50%;
  top: 0;
  z-index: 5;
  transform: translate(-50%, -100%);
  transition: transform 300ms;
  height: 68px;

  &.show {
    transform: translate(-50%, 0%);
  }

  @media (max-width: $md) {
    width: 100%;
  }
}

.container {
  display: flex;
  align-items: center;
  width: 100%;
}

.left {
  display: flex;
  align-items: center;
  flex: 1 0 auto;
}

.right {
  display: flex;
  align-items: center;
}

.logo {
  display: none;
  width: 76px;
  margin-right: 10px;

  img {
    width: 100%;
  }

  @include bp-min($xsm) {
    width: 94px;
  }

  @include bp-min($sm) {
    display: block;
  }

  @include bp-min($md) {
    width: 132px;
    margin-right: 30px;
  }

  @include bp-min($lg) {
    width: auto;
    margin-right: 91px;
  }
}
