@import "./vars.scss";
@import "./breakpoints.scss";

.container {
  margin-left: auto;
  margin-right: auto;
  min-width: 320px;
  max-width: 1600px;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;

  @include bp-min(1150px) {
    padding-left: 60px;
    padding-right: 60px;
  }

  @include bp-min(940px) {
    padding-left: 40px;
    padding-right: 40px;
  }
}
