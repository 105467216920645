@import "../../../common/vars";
@import "../../../common/breakpoints";

.header {
  border-top: 4px solid $sunset-orange;
  background: $white;
  height: 60px;
  display: flex;
  align-items: center;

  @include bp-min($md) {
    height: 88px;
  }

  @include bp-min($xmd) {
    height: 120px;
  }
}
