%cleanButton {
  background: none;
  border: none;
  padding: 0;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  outline: none;
  text-align: inherit;
}
