@import "../../../common/vars";
@import "../../../common/breakpoints";

.container {
  position: relative;
  padding: 0 30px;
  margin: 26px 0 19px;

  @include bp-min($md) {
    padding: 0;
    margin: 0;
  }
}

.button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  background: $zircon-light;
  font-size: $small-font-size;
  color: $gunmetal;
  border-radius: 6px;
  outline: none;
  padding: 5px 15px;
  width: 100%;
  text-align: left;

  @include bp-min($md) {
    font-size: inherit;
    color: inherit;
    padding: 0;
  }

  &.active {
    @include bp-min($md) {
      color: $athens-grey;
    }
  }
}

.usernameContainer {
  overflow: hidden;
  height: $large-font-size;
  position: relative;
  flex-grow: 1;

  @include bp-min($md) {
    overflow-x: initial;
    height: auto;
  }

  &:after {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 30px;
    background: linear-gradient(to right, transparent, $zircon-light 80%);

    @include bp-min($md) {
      display: none;
    }
  }
}

.chevron {
  margin-left: 4px;

  &.active {
    transform: rotate(-180deg);
  }
}
