@import "../../../common/vars";
@import "../../../common/breakpoints";

.pane {
  display: none;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  background: $charade;
  font-size: 13px;
  transition: height 300ms;
  color: #949899;

  @include bp-min($md) {
    display: flex;
  }

  > ul {
    display: flex;

    > li {
      margin-right: 28px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  a, button {
    color: $submarine;
    outline: none;

    &:hover, &:focus {
      text-decoration: underline;
    }
  }

  button {
    background: none;
    border: none;
    font-family: inherit;
    padding: 0;
  }
}

.left {
  display: flex;
}
