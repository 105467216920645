@import '../../../common/vars';
@import '../../../common/breakpoints';

.cart {
  @include bp-min($md) {
    position: relative;
  }
}

.button {
  font-family: $formular;
  font-size: $large-font-size;
  border: none;
  color: $gunmetal;
  padding: 0 8px;

  &.notEmpty,
  &.open {
    color: $sunset-orange;

    &.buttonVTB {
      color: $vtb-blue;
    }
  }

  .buttonVTB {
    font-family: $vtb-group;
  }

  @include bp-min($sm) {
    padding: 0 16px;
  }

  @include bp-min($md) {
    font-size: $regular-font-size;
    height: 44px;

    &.open {
      background: $sunset-orange;
      color: $white;

      &.buttonVTB {
        background: $vtb-blue;
        color: $white;
      }
    }
  }

  @include bp-min($xl) {
    font-size: $large-font-size;
    padding: 0 20px;
    height: 56px;
  }

  span {
    display: flex;
  }

  svg {
    margin-right: 8px;
  }
}

.cartMobileContainer {
  z-index: 5;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
